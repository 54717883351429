<template>
  <v-dialog
    v-model="showAddressModal"
    max-width="800px"
  >
    <v-card>
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card-title>
          {{ $t('companies.addOrEditCompanyAddress') }}
        </v-card-title>
        <v-row class="no-gutters px-4">
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="contact.name"
              :rules="[validator.required]"
              dense
              outlined
              :label="$t('companies.name')"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-select
              v-model="contact.address_type"
              :rules="[validator.required]"
              :items="addressTypeOptions"
              item-value="key"
              :item-text="item => $t(item.name)"
              dense
              outlined
              :label="$t('companies.addressType')"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="no-gutters px-4">
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="contact.address"
              :rules="[validator.required]"
              dense
              outlined
              :label="$t('companies.address')"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="contact.street_number"
              :rules="[validator.required]"
              dense
              outlined
              :label="$t('companies.streetNumber')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="no-gutters px-4">
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="contact.zip_code"
              :rules="[validator.required]"
              dense
              outlined
              :label="$t('companies.zipCode')"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="contact.city"
              :rules="[validator.required]"
              dense
              outlined
              :label="$t('companies.city')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="no-gutters px-4">
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="contact.state"
              dense
              outlined
              :label="$t('companies.state')"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="contact.country"
              :rules="[validator.required]"
              dense
              outlined
              :label="$t('companies.country')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="no-gutters px-4">
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="contact.address_additional_line_1"
              dense
              outlined
              :label="$t('companies.additionalAddressLine1')"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="contact.address_additional_line_2"
              dense
              outlined
              :label="$t('companies.additionalAddressLine2')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="no-gutters px-4">
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="contact.main_email_address"
              :rules="[validator.required]"
              dense
              outlined
              :label="$t('companies.email')"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="contact.correspondence_email_address"
              dense
              outlined
              :label="$t('companies.correspondenceEmail')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="no-gutters px-4">
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="contact.phone"
              :rules="[validator.required]"
              dense
              outlined
              :label="$t('companies.phone')"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="contact.mobile"
              dense
              outlined
              :label="$t('companies.mobile')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="no-gutters px-4">
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-text-field
              v-model="contact.website"
              dense
              outlined
              :label="$t('companies.website')"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="pa-1"
          >
            <v-select
              v-model="contact.language"
              :rules="[validator.required]"
              :items="languageOptions"
              :item-text="item => $t(item.name)"
              item-value="key"
              dense
              outlined
              :label="$t('companies.language')"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="no-gutters px-4">
          <v-col
            cols="12"
            class="pa-1"
          >
            <v-textarea
              v-model="contact.description"
              dense
              outlined
              :label="$t('companies.description')"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="$emit('close-address-modal')"
          >
            {{ $t('general.cancel') }}
          </v-btn>
          <v-btn
            color="success"
            @click="saveContact"
          >
            {{ $t('general.ok') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { required } from '@core/utils/validation'
import axiosIns from '@axios'

export default {
  name: 'AddAddressModal',
  props: {
    companyId: {},
    contact: {},
    showAddressModal: {},
  },
  data() {
    return {
      addressTypeOptions: this.$store.state.addressTypeOptions,
      languageOptions: this.$store.state.languages,
      validator: {
        required,
      },
    }
  },
  watch: {
    showAddressModal() {
      if (!this.showAddressModal) {
        this.$refs.form.reset()
      }
    },
  },
  methods: {
    saveContact() {
      if (this.$refs.form.validate()) {
        this.addressDataConfirm()
      }
    },

    addressDataConfirm() {
      axiosIns({
        method: this.contact.id ? 'PUT' : 'POST',
        data: this.contact,
        url: this.contact.id
          ? `/admin/companies/${this.companyId}/contacts/${this.contact.id}`
          : `/admin/companies/${this.companyId}/contacts`,
      })
        .then(
          this.$emit('new-address-added'),
        )
        .catch()
        .finally(() => {
          this.$refs.form.reset()
        })

      this.$emit('close-address-modal')
    },
  },
}
</script>
